import "../stylesheets/main.css";
import "core-js/stable";
import "regenerator-runtime/runtime";
import { $$, $ } from "../libs/bling";
import Cookies from "js-cookie";

document.addEventListener("DOMContentLoaded", () => {
    /*
    |--------------------------------------------------
    | Stripe Setup
    |--------------------------------------------------
    */

    let pk_key = document
        .querySelector('meta[name="publishable_key"]')
        .getAttribute("content");

    const stripe = Stripe(pk_key);

    /*
    |--------------------------------------------------
    | Invite Accept / Decline States
    |--------------------------------------------------
    */

    const activateButtons = (e) => {
        const $form = e.target.closest("form.actionable");
        const checkboxes = $form.querySelectorAll("input[type=checkbox]");
        const checked = Array.from(checkboxes).filter(
            ($el) => $el.checked
        ).length;

        $form.querySelector("button.btn").disabled = !checked;
    };

    $$("form.actionable input[type=checkbox]").on("change", activateButtons);

    /*
    |--------------------------------------------------
    | Checkbox
    |--------------------------------------------------
    */

    $$(".user_remember_me label").on("click", (e) => {
        let $label = e.currentTarget;
        $label.classList.remove("selected");
        let input_state = e.currentTarget.querySelector("input").checked;

        if (input_state) {
            $label.classList.add("selected");
        }
    });

    /*
    |--------------------------------------------------
    | Hamburger
    |--------------------------------------------------
    */

    $$("[data-mnav-toggle]").on("click", () => {
        const $nav = $("[data-mnav]");
        $nav.classList.toggle("shadow-xl");
        $nav.classList.toggle("translate-x-full");
    });

    $$("[data-mdropdown-toggle]").on("click", (e) => {
        e.target
            .closest("li")
            .querySelector(".mdropdown")
            .classList.toggle("open");
    });

    $$(".btn-complete-modal").forEach(($btn) => {
        $btn.on("click", (e) => {
            e.preventDefault();
            $(".modal-ov").classList.toggle("open");
        });
    });

    (() => {
        if (!$(".modal-ov")) return;

        $(".modal-ov .modal-close").on("click", (e) => {
            e.target.closest(".modal-ov").classList.toggle("open");
        });
    })();

    $$(".sitter-preview").on("click", (e) => {
        console.log("selected");
        let $el = e.currentTarget;
        let $value = $el.dataset.id;
        let $name = $el.dataset.name;
        let $currentValue = $("#review_sitter").value;

        if ($value === $currentValue) {
            $$(".sitter-preview").forEach(($sitter) =>
                $sitter.classList.remove("selected")
            );

            $el.classList.remove("selected");

            $(".review-section").classList.add("block");

            $("#review_sitter").value = "";
            $(".review-title").textContent = `Leave a review`;
            $(
                ".rating-title"
            ).textContent = `Give your sitter a rating out of 5!`;
        } else {
            $$(".sitter-preview").forEach(($sitter) =>
                $sitter.classList.remove("selected")
            );

            $el.classList.add("selected");

            $(".review-section").classList.remove("block");

            $("#review_sitter").value = $value;
            $(".review-title").textContent = `Leave a review for ${$name}`;
            $(".rating-title").textContent = `Give ${$name} a rating out of 5!`;
        }
    });

    $$("ul.rating button").on("click", (e) => {
        let $value = e.target.dataset.rating;
        $("#review_rating").value = $value;
        $(".review-section .btn").disabled = false;
        $$("ul.rating button").forEach(($btn) => {
            $btn.classList.remove("selected");
            if (parseInt($btn.dataset.rating) <= parseInt($value)) {
                $btn.classList.add("selected");
            }
        });
    });

    function updateIntercomFoundSitter(value) {
        if (window.Intercom && Intercom.booted) {
            Intercom("update", { found_sitter: value });
        }
    }

    (() => {
        if (!$("#feedback_sitter_found")) return;
        function checkFeedbackReason() {
            const $el = $("#feedback_sitter_found");
            const value = $el.value;
            console.log(value);
            if (value === "no") {
                $(".reason-field").classList.add("show");
                updateIntercomFoundSitter(false);
            } else {
                $(".reason-field").classList.remove("show");
                updateIntercomFoundSitter(true);
            }
        }

        $$("#feedback_sitter_found").on("change", checkFeedbackReason);

        // checkFeedbackReason();
    })();

    (() => {
        if (!$("[data-close-video-modal]")) return;
        $("[data-close-video-modal]").on("click", () => {
            $("[data-video-modal]").style.display = "none";
            Cookies.set("dismissed_feature_video_modal", true);
        });

        $("[data-link-video-modal]").on("click", () => {
            Cookies.set("dismissed_feature_video_modal", true);
        });
    })();

    $$(".with-sub-menu").on("mouseenter", (e) => {
        const $menu = e.target.querySelector(".sub-menu");
        $menu.classList.remove("hidden");
    });

    $$(".with-sub-menu").on("mouseleave", (e) => {
        $$(".sub-menu").forEach((e) => e.classList.add("hidden"));
    });

    $$("[data-btn-upgrade]").on("click", (e) => {
        $("[data-upgrade-loading]").classList.toggle("hidden");

        fetch("/dashboard/upgrade/checkout", {
            method: "POST",
            headers: {
                "X-CSRF-Token": document
                    .querySelector('meta[name="csrf-token"]')
                    .getAttribute("content"),
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ plan_type: e.target.dataset.plan }),
        })
            .then((resp) => resp.json())
            .then((resp) => {
                $("[data-upgrade-loading]").classList.add("hidden");
                console.log("closed loading...");
                stripe
                    .redirectToCheckout({
                        sessionId: resp.token,
                    })
                    .then((result) => {
                        // If `redirectToCheckout` fails due to a browser or network
                        // error, display the localized error message to your customer
                        // using `result.error.message`.
                    });
            });
    });

    $$("[data-close-invite-modal]").on("click", () => {
        $("[data-invite-modal]").remove();
    });

    (() => {
        if (!$(".tw-flash-message")) return;
        setTimeout(() => {
            $(".tw-flash-message").classList.add("-translate-y-full");
        }, 2000);
    })();

    /*
    |--------------------------------------------------
    | Cookie Acceptance
    |--------------------------------------------------
    */

    $("#cookie_notice_ok").on("click", () => {
        Cookies.set("cookies_accepted", true);
        $("#cookie_notice").classList.toggle("hidden");
    });
});
